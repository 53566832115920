import React from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import ReactGA from 'react-ga';
import 'bulma/css/bulma.min.css'
import './App.css'

import Main from "../containers/Main/Main";
import Dashboard from "../containers/Dashboard/Dashboard";
import Session from "../containers/Session/Session";
import NotFound from '../components/404/404'

import RouterChangeTracker from "../utils/RouterChangeTracker";

const TRACKING_ID = "G-QCB1QR68SJ"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

class App extends React.Component {
  render() {
    return (
      <Router>
        <RouterChangeTracker />
        <Switch>
          <Route path="/" exact component={Main} />
          <Route path="/dashboard" component={Dashboard} exact />
          <Route path="/session" component={Session} exact />
          <Route path="*" component={NotFound} />
        </Switch>
      </Router>
    );
  }
}

export default App;
