import React from "react";
import PageLoader from "../../components/PageLoader/PageLoader";
import Logo from "../../assets/logo.png";

class Main extends React.Component {
  state = {
    goal: "",
    loader: true,
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        loader: false,
      });
    }, 300);
    localStorage.clear();
  }

  changeHandler = (event) => {
    this.setState({ goal: event.target.value });
  };

  handleSubmit = () => {
    localStorage.setItem("goal", this.state.goal);
    this.props.history.push({
      pathname: "/dashboard",
    });
  };

  handleKeyDown = (e, cb) => {
    if (e.key === "Enter" && e.shiftKey === false) {
      e.preventDefault();
      cb();
    }
  };

  render() {
    return (
      <section className="start-hero-main is-medium is-bold hero-custom-bg">
        <PageLoader loader={this.state.loader} title="Loading..." />
        <div className="hero-body-session-custom">
          <div className="container has-text-centered">
            <div className="columns">
              <div className="column is-three-fifths is-offset-one-fifth">
                <div className="has-text-centered logo-padding">
                  <img src={Logo} width="60" alt="logo" />
                </div>
                <h1 className="title custom-title">
                  What goal do you want to set for yourself today?
                </h1>
                <h2 className="messages-subtitle">
                  The next screen will let you break that goal down into
                  achievable & focused sessions.
                </h2>
                <form onSubmit={() => this.handleSubmit()}>
                  <div className="control">
                    <input
                      className="textarea is-medium"
                      onChange={this.changeHandler}
                      placeholder="Complete the new website design and send off to the development agency by 5pm"
                      rows="3"
                      value={this.state.goal}
                      autoFocus
                    />
                  </div>
                  <br />
                  <button
                    disabled={!this.state.goal}
                    className="button is-large is-white is-outlined has-text-weight-semibold is-fullwidth m-0"
                    type="submit"
                  >
                    Set this goal
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Main;
