import React from "react";
import { Link } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import {
  BulbOutlined,
  CheckCircleFilled,
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
  FieldTimeOutlined,
  FrownOutlined,
  PlusOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import Logo from "../../assets/logo.png";

import {
  GiMeditation,
  GiSoundOff,
  GiHeavyRain,
  GiForestCamp,
  GiCampfire,
  GiEgyptianBird,
  GiButterflyFlower,
  GiWaterfall,
} from "react-icons/all";

import Finish from "../../assets/finish.svg";
import Session from "../../assets/session.svg";
import Swal from "sweetalert2";

import ForestFile from "../../sound/birds-song-in-forest.mp3";
import MeditationFile from "../../sound/432-hz-music-for-relaxation.mp3";
import OceanFile from "../../sound/ocean-waves-noise.mp3";
import FireFile from "../../sound/Fire-flame-sound-effect.mp3";
import BirdsFile from "../../sound/birds-noises.mp3";
import RelaxingFile from "../../sound/relaxing-tone.mp3";
import RainingFile from "../../sound/rain-ambience.mp3";

const ForestAudio = new Audio(ForestFile);
const MeditationAudio = new Audio(MeditationFile);
const OceanAudio = new Audio(OceanFile);
const FireAudio = new Audio(FireFile);
const BirdsAudio = new Audio(BirdsFile);
const RelaxingAudio = new Audio(RelaxingFile);
const RainingAudio = new Audio(RainingFile);

class Dashboard extends React.Component {
  state = {
    newSession: "",
    session: [],
    time: 30,
    goal:
      localStorage.getItem("goal") || "Please set your main goal for today!",
    editScreen: false,
    sessionIndex: "",
    currentId: "",
    isEditGoal: false,
    setSession: this.props.location.setSession,
    sessionId: this.props.location.sessionId,
    done: true,
    break: true,
    soundName: localStorage.getItem("soundName") || "mute",
  };

  componentDidMount() {
    const session = localStorage.getItem("session");
    const parsedSession = JSON.parse(session);
    if (session != null) {
      this.setState({
        session: parsedSession,
      });
    }
  }

  togglePlay = (type) => {
    if (type === "meditation") {
      MeditationAudio.play();
    }
    if (type === "raining") {
      RainingAudio.play();
    }
    if (type === "forest") {
      ForestAudio.play();
    }
    if (type === "relaxing") {
      RelaxingAudio.play();
    }
    if (type === "ocean") {
      OceanAudio.play();
    }
    if (type === "fire") {
      FireAudio.play();
    }
    if (type === "birds") {
      BirdsAudio.play();
    }
  };

  toggleStop = (type) => {
    if (type === "meditation") {
      MeditationAudio.pause();
    }
    if (type === "raining") {
      RainingAudio.pause();
    }
    if (type === "forest") {
      ForestAudio.pause();
    }
    if (type === "relaxing") {
      RelaxingAudio.pause();
    }
    if (type === "ocean") {
      OceanAudio.pause();
    }
    if (type === "fire") {
      FireAudio.pause();
    }
    if (type === "birds") {
      BirdsAudio.pause();
    }
  };

  updateInput(key, value) {
    this.setState({
      [key]: value,
    });
  }

  editSession(id, data, index) {
    this.setState({
      editScreen: !this.state.editScreen,
      newSession: data.title,
      time: data.time,
      sessionIndex: index,
      currentId: data.id,
      done: true,
      break: true,
    });
  }

  updateSession = (id) => {
    let { session, newSession, time } = this.state;
    session.map((item) => {
      if (item.id === id) {
        item.title = newSession;
        item.time = time;
      }
      return true;
    });

    localStorage.setItem("session", JSON.stringify(session));
    this.setState({
      session: JSON.parse(localStorage.getItem("session")),
      newSession: "",
      time: 30,
      editScreen: false,
    });
  };

  deleteSession(id) {
    Swal.fire({
      title: "Are you sure you want to delete this session?",
      showCancelButton: true,
      confirmButtonColor: "#8964f3",
      confirmButtonText: "Delete session",
    }).then((result) => {
      if (result.value) {
        const session = [...this.state.session];
        const indexSession = session.findIndex((item) => item.id === id);

        let sessionValue = JSON.parse(localStorage.getItem("session"));
        sessionValue.splice(indexSession, 1);
        this.setState({
          session: sessionValue,
        });
        localStorage.setItem("session", JSON.stringify(sessionValue));
      }
    });
  }

  addSession(num) {
    const newSession = {
      id: 1 + Math.random(),
      title: this.state.newSession.slice(),
      time: this.state.time,
      complete: false,
      sessionNumber: num,
    };

    if (localStorage.getItem("session") == null) {
      const session = [...this.state.session];
      session.push(newSession);
      localStorage.setItem("session", JSON.stringify(session));
    } else {
      const session = JSON.parse(localStorage.getItem("session"));
      session.push(newSession);
      localStorage.setItem("session", JSON.stringify(session));
    }

    this.setState({
      session: JSON.parse(localStorage.getItem("session")),
      newSession: "",
      time: 30,
    });
  }

  handleKeyDown = (e, cb) => {
    if (e.key === "Enter" && e.shiftKey === false) {
      e.preventDefault();
      cb();
    }
  };

  addNewSession = () => {
    this.setState({
      newSession: "",
      time: 30,
      done: true,
      break: true,
    });
  };

  truncate = (input) =>
    input.length > 40 ? `${input.substring(0, 40)}...` : input;

  editTheGoal = () => {
    this.setState({ isEditGoal: !this.state.isEditGoal });
  };

  saveTheGoal = () => {
    this.setState({ goal: this.state.goal, isEditGoal: false });
    localStorage.setItem("goal", this.state.goal);
  };

  handleDone = (id) => {
    let { done, session } = this.state;
    if (done) {
      session.map((item) => {
        if (item.id === id) {
          item.complete = true;
        }
        return true;
      });

      localStorage.setItem("session", JSON.stringify(session));
      this.setState({
        session,
        newSession: "",
        time: 30,
        editScreen: false,
        setSession: false,
        break: false,
      });
    } else {
      this.setState({
        session,
        newSession: "",
        time: 30,
        editScreen: false,
        setSession: false,
        break: true,
      });
    }
  };

  goSession = (activeSession) => {
    this.props.history.push({
      pathname: "/session",
      sessionNumber: activeSession[0].sessionNumber,
      state: activeSession[0],
      soundName: this.state.soundName,
    });
  };

  onDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const session = Object.assign([], this.state.session);
    const project = this.state.session[source.index];
    session.splice(source.index, 1);
    session.splice(destination.index, 0, project);

    localStorage.setItem("session", JSON.stringify(session));
    this.setState({
      session: JSON.parse(localStorage.getItem("session")),
    });
  };

  convert = (n) =>
    `0${(n / 60) ^ 0}`.slice(-2) + ":" + ("0" + (n % 60)).slice(-2);

  render() {
    const { session, goal, newSession } = this.state;
    const lastDataIndex = session.lastIndexOf(session[session.length - 1]) + 2;
    const activeSession = this.state.session.filter(
      (item) => item.complete !== true
    );

    const totalMinute = [];
    session.map((item) =>
      item.complete ? null : totalMinute.push(parseInt(item.time))
    );

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <div className="columns scroll-main">
          <div
            className="column is-4 messages hero is-fullheight"
            // style={{ borderTopRightRadius: "75px" }}
          >
            <div className="main-sidebar">
              <h1 className="messages-title is-size-3 is-uppercase">
                <Link to={"/"}>
                  <img src={Logo} width="60" alt="logo" />
                </Link>
                {/* Your day plan */}
              </h1>
              <br />
              <h2 className="messages-subtitle is-size-5 is-uppercase">
                Today's goal
              </h2>

              <div className="card-content p-0">
                {this.state.isEditGoal ? (
                  <form
                    onSubmit={this.saveTheGoal}
                    onKeyDown={(e) => {
                      this.handleKeyDown(e, this.saveTheGoal);
                    }}
                  >
                    <div className="field has-addons">
                      <p className="control is-expanded">
                        <input
                          onChange={(e) =>
                            this.updateInput("goal", e.target.value)
                          }
                          className="input custom-input"
                          type="text"
                          value={goal}
                        />
                      </p>
                      <p className="control">
                        <span className="button" onClick={this.saveTheGoal}>
                          <CheckOutlined />
                        </span>
                      </p>
                    </div>
                  </form>
                ) : (
                  <div className="columns" onDoubleClick={this.editTheGoal}>
                    <div className="column" style={{ padding: 0 }}>
                      <BulbOutlined style={{ color: "white" }} />
                    </div>
                    <Tippy content={goal}>
                      <div
                        className="column is-two-thirds messages-goal-text mr-6"
                        style={{ padding: 0 }}
                      >
                        {this.truncate(goal)}
                      </div>
                    </Tippy>
                    <div
                      className="column"
                      onClick={this.editTheGoal}
                      style={{ padding: 0, textAlign: "end" }}
                    >
                      <EditOutlined style={{ color: "white" }} />
                    </div>
                  </div>
                )}
              </div>

              <br />
              <div className="inbox-messages">
                <div className="">
                  <h2 className="messages-subtitle is-size-5 is-uppercase">
                    Planned session
                  </h2>
                  {activeSession.length ? (
                    <div
                      style={{ paddingBottom: this.state.break ? null : 20 }}
                    >
                      {this.state.break ? null : (
                        <button
                          onClick={this.addNewSession}
                          className="button is-medium is-white is-outlined has-text-weight-semibold is-fullwidth m-0"
                        >
                          <PlusOutlined /> Add new session
                        </button>
                      )}
                    </div>
                  ) : null}
                  <div className="scroll">
                    {session.length ? null : (
                      <React.Fragment>
                        <div className="inbox-messages finish-container">
                          <img src={Session} alt="session" />
                          <br />
                          <p className="small-subtitle has-text-weight-semibold">
                            Once you create a session it will appear here!
                          </p>
                        </div>
                      </React.Fragment>
                    )}
                    <Droppable droppableId="col-1">
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {session.map((item, index) => {
                            return (
                              <Draggable
                                isDragDisabled={item.complete}
                                draggableId={item.id.toString()}
                                index={index}
                                key={item.id}
                              >
                                {(provided) => (
                                  <div
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                    className="card"
                                  >
                                    <header className="card-header">
                                      <p
                                        className={
                                          item.complete
                                            ? "card-header-title is-uppercase cross-out"
                                            : "card-header-title is-uppercase"
                                        }
                                      >
                                        {item.complete && (
                                          <CheckCircleFilled className="mr-1 has-text-success" />
                                        )}{" "}
                                        Session #{index + 1}
                                      </p>
                                      <span
                                        className="card-header-icon"
                                        aria-label="more options"
                                      >
                                        <span
                                          className={
                                            item.complete
                                              ? "is-uppercase has-text-weight-semibold cross-out"
                                              : "is-uppercase has-text-weight-semibold"
                                          }
                                        >
                                          {item.time} Min
                                        </span>
                                      </span>
                                    </header>
                                    <div className="card-content">
                                      <div
                                        className={
                                          item.complete
                                            ? "content content-card-p cross-out"
                                            : "content content-card-p"
                                        }
                                      >
                                        {item.title}
                                      </div>
                                    </div>
                                    {item.complete ? null : (
                                      <footer className="card-footer">
                                        <span
                                          className="card-footer-item card-header-icon"
                                          onClick={() => {
                                            this.deleteSession(item.id);
                                          }}
                                        >
                                          <DeleteOutlined
                                            style={{ color: "grey" }}
                                          />
                                        </span>
                                        <span
                                          className="card-footer-item card-header-icon"
                                          onClick={() =>
                                            this.editSession(
                                              item.id,
                                              item,
                                              index + 1
                                            )
                                          }
                                        >
                                          <EditOutlined
                                            style={{ color: "grey" }}
                                          />
                                        </span>
                                      </footer>
                                    )}
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>
                  <div>
                    <p className="messages-subtitle mt-3 is-size-5 is-uppercase">
                      Total:{" "}
                      {`${this.convert(
                        Math.round(
                          totalMinute.reduce((a, b) => a + b, 0),
                          2
                        )
                      )} Hours`}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="column is-7 message hero is-fullheight">
            <div className="container">
              {this.state.break ? (
                <React.Fragment>
                  {this.state.setSession ? (
                    <React.Fragment>
                      <h1 className="title is-size-1">
                        Did you complete your task?
                      </h1>
                      <div className="inbox-messages notification">
                        <div className="card">
                          <header className="card-header">
                            <p className="card-header-title is-uppercase">
                              <CheckCircleFilled className="mr-1 has-text-success" />{" "}
                              Session #{this.props.location.sessionNumber}
                            </p>
                            <span
                              className="card-header-icon"
                              aria-label="more options"
                            >
                              <span className="is-uppercase has-text-weight-semibold">
                                {this.props.location.session.time} Min
                              </span>
                            </span>
                          </header>
                          <div className="card-content">
                            <div className="content content-card-p">
                              {" "}
                              {this.props.location.session.title}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="control radio-container">
                        <label className="label-control">
                          <input
                            value={true}
                            type="radio"
                            name="answer"
                            defaultChecked
                          />
                          <div onClick={(e) => this.updateInput("done", true)}>
                            <SmileOutlined style={{ fontSize: "30px" }} />{" "}
                            <br />{" "}
                            <span className="has-text-weight-semibold">
                              Yes
                            </span>
                          </div>
                        </label>
                        <label className="label-control">
                          <input value={false} type="radio" name="answer" />
                          <div onClick={(e) => this.updateInput("done", false)}>
                            <FrownOutlined style={{ fontSize: "30px" }} />{" "}
                            <br />{" "}
                            <span className="has-text-weight-semibold">No</span>
                          </div>
                        </label>
                      </div>

                      <button
                        className="button is-large is-button-custom has-text-weight-semibold is-fullwidth"
                        onClick={() =>
                          this.handleDone(this.props.location.session.id)
                        }
                      >
                        Continue
                      </button>
                    </React.Fragment>
                  ) : (
                    <div>
                      {lastDataIndex === 1 && (
                        <div className="inbox-messages notification has-text-centered p-6">
                          <h1 className="title has-text-weight-bold is-size-1">
                            Awesome
                            {/* <MehOutlined style={{ color: "#8c66f3" }} /> */}
                            <span role="img" aria-label="emoji">
                              👏
                            </span>
                          </h1>
                          <h2 className="subtitle">
                            Now break your goal into clearly-defined and
                            manageable focus sessions. We'll prompt you to take
                            breaks in between to keep you on your best form.
                          </h2>
                        </div>
                      )}

                      <br />

                      {this.state.editScreen ? (
                        <div>
                          <h1 className="title is-size-1">
                            Edit Session #{this.state.sessionIndex}
                          </h1>

                          <form
                            onSubmit={() =>
                              this.updateSession(this.state.currentId)
                            }
                          >
                            <div className="field">
                              {lastDataIndex === 1 ? (
                                <label className="label is-size-5">
                                  In this session I will...
                                </label>
                              ) : (
                                <label className="label is-size-5">
                                  My task is to
                                </label>
                              )}
                              <div className="field has-addons">
                                <p className="control has-icons-left">
                                  <span className="select is-medium">
                                    <select
                                      onChange={(e) =>
                                        this.updateInput("time", e.target.value)
                                      }
                                      value={this.state.time}
                                    >
                                      <option value="30">30 mins</option>
                                      <option value="20">20 mins</option>
                                      <option value="45">45 mins</option>
                                      <option value="60">60 mins</option>
                                    </select>
                                  </span>
                                  <span className="icon is-small is-left">
                                    <FieldTimeOutlined
                                      style={{ color: "grey" }}
                                    />
                                  </span>
                                </p>
                                <p className="control is-expanded">
                                  <input
                                    className="input is-medium"
                                    type="text"
                                    value={newSession}
                                    onChange={(e) =>
                                      this.updateInput(
                                        "newSession",
                                        e.target.value
                                      )
                                    }
                                    placeholder="Finish designing the new check out process"
                                    required
                                    autoFocus
                                  />
                                </p>
                              </div>
                            </div>
                            <button
                              type="submit"
                              disabled={!this.state.newSession}
                              className="button is-button-custom is-large has-text-weight-semibold is-fullwidth"
                            >
                              Save changes
                            </button>
                          </form>
                        </div>
                      ) : (
                        <div>
                          <h1 className="title is-size-1">
                            Session #{lastDataIndex}
                          </h1>
                          <h2 className="subtitle">
                            You now have {session.length} sessions planned!
                          </h2>
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                              this.addSession(lastDataIndex);
                            }}
                          >
                            <div className="field">
                              {lastDataIndex === 1 ? (
                                <label className="label is-size-5">
                                  In this session I will...
                                </label>
                              ) : (
                                <label className="label is-size-5">
                                  My task is to
                                </label>
                              )}
                              <div className="field has-addons">
                                <p className="control has-icons-left">
                                  <span className="select is-medium">
                                    <select
                                      onChange={(e) =>
                                        this.updateInput("time", e.target.value)
                                      }
                                      value={this.state.time}
                                    >
                                      <option value="1">1 mins</option>
                                      <option value="30">30 mins</option>
                                      <option value="20">20 mins</option>
                                      <option value="45">45 mins</option>
                                      <option value="60">60 mins</option>
                                    </select>
                                  </span>
                                  <span className="icon is-small is-left">
                                    <FieldTimeOutlined
                                      style={{ color: "grey" }}
                                    />
                                  </span>
                                </p>
                                <p className="control is-expanded">
                                  <input
                                    className="input is-medium"
                                    type="text"
                                    value={newSession}
                                    onChange={(e) =>
                                      this.updateInput(
                                        "newSession",
                                        e.target.value
                                      )
                                    }
                                    placeholder="Finish designing the new check out process"
                                    required
                                    autoFocus
                                  />
                                </p>
                              </div>
                            </div>
                            <button
                              type="submit"
                              disabled={!this.state.newSession}
                              className="button is-button-custom is-large has-text-weight-semibold is-fullwidth"
                            >
                              Add another session
                            </button>
                          </form>
                        </div>
                      )}
                      {lastDataIndex === 1 && (
                        <div
                          className="inbox-messages notification has-text-centered p-6 mt-6"
                          style={{ marginTop: 30 }}
                        >
                          <h1 className="title">
                            Just want to start without planning
                          </h1>
                          <h2 className="subtitle">
                            Start a 30 min session for you task.
                          </h2>
                          <Link
                            to={{
                              pathname: "/session",
                              sessionNumber: false,
                              state: {
                                id: 1 + Math.random(),
                                title: "One day, One goal",
                                time: 30,
                                complete: false,
                              },
                            }}
                          >
                            <button className="button is-button-custom is-large has-text-weight-semibold is-fullwidth">
                              Start session
                            </button>
                          </Link>
                        </div>
                      )}

                      {lastDataIndex !== 1 && (
                        <div style={{ paddingTop: 10 }}>
                          <hr />
                          <h2 className="subtitle has-text-centered has-text-weight-semibold is-size-3">
                            Finished planing and want to get cracking?
                          </h2>
                          <p className="has-text-centered">
                            You can chose you sound while you working on a
                            project or just skip and listen music on spotify.
                            <br />
                            Just hover over the card to listen to the sounds.
                          </p>
                          <div className="control radio-container">
                            <label className="label-control">
                              <input
                                value={true}
                                type="radio"
                                name="answer"
                                defaultChecked={
                                  this.state.soundName === "mute" ? true : false
                                }
                              />
                              <div
                                onMouseOver={() => this.togglePlay("mute")}
                                onMouseLeave={() => this.toggleStop("mute")}
                                onClick={(e) =>
                                  localStorage.setItem("soundName", "mute")
                                }
                              >
                                <GiSoundOff size="40" />
                                <span className="has-text-weight-semibold">
                                  No sound
                                </span>
                              </div>
                            </label>
                            <label className="label-control">
                              <input
                                value={false}
                                type="radio"
                                name="answer"
                                defaultChecked={
                                  this.state.soundName === "meditation"
                                    ? true
                                    : false
                                }
                              />
                              <div
                                onMouseOver={() =>
                                  this.togglePlay("meditation")
                                }
                                onMouseLeave={() =>
                                  this.toggleStop("meditation")
                                }
                                onClick={(e) =>
                                  localStorage.setItem(
                                    "soundName",
                                    "meditation"
                                  )
                                }
                              >
                                <GiMeditation size="40" />
                                <span className="has-text-weight-semibold">
                                  Meditation
                                </span>
                              </div>
                            </label>
                            <label className="label-control">
                              <input
                                value={false}
                                type="radio"
                                name="answer"
                                defaultChecked={
                                  this.state.soundName === "raining"
                                    ? true
                                    : false
                                }
                              />
                              <div
                                onMouseOver={() => this.togglePlay("raining")}
                                onMouseLeave={() => this.toggleStop("raining")}
                                onClick={(e) =>
                                  localStorage.setItem("soundName", "raining")
                                }
                              >
                                <GiHeavyRain size="40" />
                                <span className="has-text-weight-semibold">
                                  Raining
                                </span>
                              </div>
                            </label>
                            <label className="label-control">
                              <input
                                value={false}
                                type="radio"
                                name="answer"
                                defaultChecked={
                                  this.state.soundName === "forest"
                                    ? true
                                    : false
                                }
                              />
                              <div
                                onMouseOver={() => this.togglePlay("forest")}
                                onMouseLeave={() => this.toggleStop("forest")}
                                onClick={(e) =>
                                  localStorage.setItem("soundName", "forest")
                                }
                              >
                                <GiForestCamp size="40" />
                                <span className="has-text-weight-semibold">
                                  Forest
                                </span>
                              </div>
                            </label>
                            <label className="label-control">
                              <input
                                value={false}
                                type="radio"
                                name="answer"
                                defaultChecked={
                                  this.state.soundName === "relaxing"
                                    ? true
                                    : false
                                }
                              />
                              <div
                                onMouseOver={() => this.togglePlay("relaxing")}
                                onMouseLeave={() => this.toggleStop("relaxing")}
                                onClick={(e) =>
                                  localStorage.setItem("soundName", "relaxing")
                                }
                              >
                                <GiButterflyFlower size="40" />
                                <span className="has-text-weight-semibold">
                                  Relaxing
                                </span>
                              </div>
                            </label>
                            <label className="label-control">
                              <input
                                value={false}
                                type="radio"
                                name="answer"
                                defaultChecked={
                                  this.state.soundName === "ocean"
                                    ? true
                                    : false
                                }
                              />
                              <div
                                onMouseOver={() => this.togglePlay("ocean")}
                                onMouseLeave={() => this.toggleStop("ocean")}
                                onClick={(e) =>
                                  localStorage.setItem("soundName", "ocean")
                                }
                              >
                                <GiWaterfall size="40" />
                                <span className="has-text-weight-semibold">
                                  Ocean
                                </span>
                              </div>
                            </label>
                            <label className="label-control">
                              <input
                                value={false}
                                type="radio"
                                name="answer"
                                defaultChecked={
                                  this.state.soundName === "fire" ? true : false
                                }
                              />
                              <div
                                onMouseOver={() => this.togglePlay("fire")}
                                onMouseLeave={() => this.toggleStop("fire")}
                                onClick={(e) =>
                                  localStorage.setItem("soundName", "fire")
                                }
                              >
                                <GiCampfire size="40" />
                                <span className="has-text-weight-semibold">
                                  Fire
                                </span>
                              </div>
                            </label>
                            <label className="label-control">
                              <input
                                value={false}
                                type="radio"
                                name="answer"
                                defaultChecked={
                                  this.state.soundName === "birds"
                                    ? true
                                    : false
                                }
                              />
                              <div
                                onMouseOver={() => this.togglePlay("birds")}
                                onMouseLeave={() => this.toggleStop("birds")}
                                onClick={(e) =>
                                  localStorage.setItem("soundName", "birds")
                                }
                              >
                                <GiEgyptianBird size="40" />
                                <span className="has-text-weight-semibold">
                                  Birds
                                </span>
                              </div>
                            </label>
                          </div>
                          <button
                            onClick={() => this.goSession(activeSession)}
                            className="button is-large is-button-custom has-text-weight-semibold is-fullwidth"
                          >
                            Start session
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {!activeSession ? null : activeSession.length ? (
                    <React.Fragment>
                      <h1 className="title is-size-1">Take a break!</h1>
                      <h2 className="subtitle">Your next session is</h2>
                      <div className="inbox-messages notification">
                        <div className="card">
                          <header className="card-header">
                            <p className="card-header-title is-uppercase">
                              Session #{activeSession[0].sessionNumber}
                            </p>
                            <span
                              className="card-header-icon"
                              aria-label="more options"
                            >
                              <span className="is-uppercase has-text-weight-semibold">
                                {activeSession[0].time} Min
                              </span>
                            </span>
                          </header>
                          <div className="card-content">
                            <div className="content content-card-p">
                              {" "}
                              {activeSession[0].title}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div style={{ paddingTop: 10 }}>
                        <p className="has-text-centered">
                          You can chose you sound while you working on a project
                          or just skip and listen music on spotify.
                          <br />
                          Just hover over the card to listen to the sounds.
                        </p>
                        <div className="control radio-container">
                          <label className="label-control">
                            <input
                              value={true}
                              type="radio"
                              name="answer"
                              defaultChecked={
                                this.state.soundName === "mute" ? true : false
                              }
                            />
                            <div
                              onMouseOver={() => this.togglePlay("mute")}
                              onMouseLeave={() => this.toggleStop("mute")}
                              onClick={(e) =>
                                localStorage.setItem("soundName", "mute")
                              }
                            >
                              <GiSoundOff size="40" />
                              <span className="has-text-weight-semibold">
                                No sound
                              </span>
                            </div>
                          </label>
                          <label className="label-control">
                            <input
                              value={false}
                              type="radio"
                              name="answer"
                              defaultChecked={
                                this.state.soundName === "meditation"
                                  ? true
                                  : false
                              }
                            />
                            <div
                              onMouseOver={() => this.togglePlay("meditation")}
                              onMouseLeave={() => this.toggleStop("meditation")}
                              onClick={(e) =>
                                localStorage.setItem("soundName", "meditation")
                              }
                            >
                              <GiMeditation size="40" />
                              <span className="has-text-weight-semibold">
                                Meditation
                              </span>
                            </div>
                          </label>
                          <label className="label-control">
                            <input
                              value={false}
                              type="radio"
                              name="answer"
                              defaultChecked={
                                this.state.soundName === "raining"
                                  ? true
                                  : false
                              }
                            />
                            <div
                              onMouseOver={() => this.togglePlay("raining")}
                              onMouseLeave={() => this.toggleStop("raining")}
                              onClick={(e) =>
                                localStorage.setItem("soundName", "raining")
                              }
                            >
                              <GiHeavyRain size="40" />
                              <span className="has-text-weight-semibold">
                                Raining
                              </span>
                            </div>
                          </label>
                          <label className="label-control">
                            <input
                              value={false}
                              type="radio"
                              name="answer"
                              defaultChecked={
                                this.state.soundName === "forest" ? true : false
                              }
                            />
                            <div
                              onMouseOver={() => this.togglePlay("forest")}
                              onMouseLeave={() => this.toggleStop("forest")}
                              onClick={(e) =>
                                localStorage.setItem("soundName", "forest")
                              }
                            >
                              <GiForestCamp size="40" />
                              <span className="has-text-weight-semibold">
                                Forest
                              </span>
                            </div>
                          </label>
                          <label className="label-control">
                            <input
                              value={false}
                              type="radio"
                              name="answer"
                              defaultChecked={
                                this.state.soundName === "relaxing"
                                  ? true
                                  : false
                              }
                            />
                            <div
                              onMouseOver={() => this.togglePlay("relaxing")}
                              onMouseLeave={() => this.toggleStop("relaxing")}
                              onClick={(e) =>
                                localStorage.setItem("soundName", "relaxing")
                              }
                            >
                              <GiButterflyFlower size="40" />
                              <span className="has-text-weight-semibold">
                                Relaxing
                              </span>
                            </div>
                          </label>
                          <label className="label-control">
                            <input
                              value={false}
                              type="radio"
                              name="answer"
                              defaultChecked={
                                this.state.soundName === "ocean" ? true : false
                              }
                            />
                            <div
                              onMouseOver={() => this.togglePlay("ocean")}
                              onMouseLeave={() => this.toggleStop("ocean")}
                              onClick={(e) =>
                                localStorage.setItem("soundName", "ocean")
                              }
                            >
                              <GiWaterfall size="40" />
                              <span className="has-text-weight-semibold">
                                Ocean
                              </span>
                            </div>
                          </label>
                          <label className="label-control">
                            <input
                              value={false}
                              type="radio"
                              name="answer"
                              defaultChecked={
                                this.state.soundName === "fire" ? true : false
                              }
                            />
                            <div
                              onMouseOver={() => this.togglePlay("fire")}
                              onMouseLeave={() => this.toggleStop("fire")}
                              onClick={(e) =>
                                localStorage.setItem("soundName", "fire")
                              }
                            >
                              <GiCampfire size="40" />
                              <span className="has-text-weight-semibold">
                                Fire
                              </span>
                            </div>
                          </label>
                          <label className="label-control">
                            <input
                              value={false}
                              type="radio"
                              name="answer"
                              defaultChecked={
                                this.state.soundName === "birds" ? true : false
                              }
                            />
                            <div
                              onMouseOver={() => this.togglePlay("birds")}
                              onMouseLeave={() => this.toggleStop("birds")}
                              onClick={(e) =>
                                localStorage.setItem("soundName", "birds")
                              }
                            >
                              <GiEgyptianBird size="40" />
                              <span className="has-text-weight-semibold">
                                Birds
                              </span>
                            </div>
                          </label>
                        </div>
                      </div>

                      <button
                        className="button is-large is-button-custom has-text-weight-semibold is-fullwidth"
                        onClick={() => this.goSession(activeSession)}
                      >
                        Start next session now
                      </button>
                    </React.Fragment>
                  ) : (
                    <div>
                      <h1 className="title is-size-1">
                        Great you have done all your works!
                      </h1>
                      <h2 className="subtitle">Now it's time to rest</h2>
                      <div className="inbox-messages finish-container">
                        <img width={"512px"} src={Finish} alt="finish" />
                      </div>
                      <Link
                        to={{
                          pathname: "/",
                        }}
                      >
                        <button className="button is-large is-button-custom has-text-weight-semibold is-fullwidth">
                          Start a new session
                        </button>
                      </Link>
                    </div>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </DragDropContext>
    );
  }
}

export default Dashboard;
