import React from "react";
import "bulma-extensions/bulma-pageloader/dist/css/bulma-pageloader.min.css";

const pageLoader = (props) => (
  <div className={props.loader ? "pageloader is-active" : "pageloader"}>
    <span className="title">{props.title}</span>
  </div>
);

export default pageLoader;
