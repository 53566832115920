import React from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import Swal from "sweetalert2";
import {
  CheckCircleOutlined,
  CloseOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
  RedoOutlined,
  SoundFilled,
  SoundOutlined,
} from "@ant-design/icons";
import Logo from "../../assets/logo.png";

import soundFinish from "../../sound/sport-start-bleeps.wav";

import ForestFile from "../../sound/birds-song-in-forest.mp3";
import MeditationFile from "../../sound/432-hz-music-for-relaxation.mp3";
import OceanFile from "../../sound/ocean-waves-noise.mp3";
import FireFile from "../../sound/Fire-flame-sound-effect.mp3";
import BirdsFile from "../../sound/birds-noises.mp3";
import RelaxingFile from "../../sound/relaxing-tone.mp3";
import RainingFile from "../../sound/rain-ambience.mp3";

const ForestAudio = new Audio(ForestFile);
const MeditationAudio = new Audio(MeditationFile);
const OceanAudio = new Audio(OceanFile);
const FireAudio = new Audio(FireFile);
const BirdsAudio = new Audio(BirdsFile);
const RelaxingAudio = new Audio(RelaxingFile);
const RainingAudio = new Audio(RainingFile);
const soundFinishAudio = new Audio(soundFinish);

class Session extends React.Component {
  state = {
    totalMinutes: this.props.location.state.time,
    minutes: this.props.location.state.time,
    soundName: localStorage.getItem("soundName") || "mute",
    seconds: 0,
    play: false,
    volume: false,
    time: 0,
    totalSeconds: 0,
    count: 0,
  };

  componentDidMount() {
    this.setState({ totalSeconds: parseFloat(this.state.totalMinutes * 60) });
    this.handlePlay();
    this.handleVolume();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handleIncrement = () => {
    const { totalSeconds, time } = this.state;
    const calcTotalSeconds = totalSeconds - time;
    this.setState((state) => ({
      time: ++state.count / 2,
    }));

    if (calcTotalSeconds === 3) {
      soundFinishAudio.play();
    }
  };

  startTime = () => {
    this.interval = setInterval(() => {
      const { seconds, minutes } = this.state;

      this.handleIncrement();

      if (seconds > 0) {
        this.setState(({ seconds }) => ({
          seconds: seconds - 1,
        }));
      }
      if (seconds === 0) {
        if (minutes === 0) {
          this.closeSession();
        } else {
          this.setState(({ minutes }) => ({
            minutes: minutes - 1,
            seconds: 59,
          }));
        }
      }
    }, 1000);
  };

  stopTimer = () => {
    clearInterval(this.interval);
  };

  handleRefresh = () => {
    Swal.fire({
      title: "Are you sure you want to restart the session?",
      showCancelButton: true,
      confirmButtonColor: "#8964f3",
      confirmButtonText: "Restart Session",
    }).then((result) => {
      if (result.value) {
        this.toggleCurrentTime(this.state.soundName);
        this.stopTimer();
        this.setState({
          minutes: this.props.location.state.time,
          totalMinutes: this.props.location.state.time,
          seconds: 0,
          time: 0,
        });
        this.startTime();
      }
    });
  };

  closeSession = () => {
    this.togglePause(this.state.soundName);
    this.toggleMute(this.state.soundName, true);
    this.stopTimer();
    this.setState({
      minutes: this.props.location.state.time,
      totalMinutes: this.props.location.state.time,
      seconds: 0,
    });
    this.props.history.push({
      pathname: "/dashboard",
      setSession: true,
      sessionNumber: this.props.location.sessionNumber,
      session: this.props.location.state,
    });
  };

  handleClose = () => {
    Swal.fire({
      title: "Are you sure you want to end the session early?",
      showCancelButton: true,
      confirmButtonColor: "#8964f3",
      confirmButtonText: "End Session",
    }).then((result) => {
      if (result.value) {
        this.closeSession();
      }
    });
  };

  handleCompleted = () => {
    Swal.fire({
      type: "success",
      title: "Completed.",
      showConfirmButton: false,
      timer: 1500,
    }).then(() => {
      this.closeSession();
    });
  };

  handlePlay = () => {
    this.setState({ totalSeconds: parseFloat(this.state.totalMinutes * 60) });
    this.setState({ play: !this.state.play }, () => {
      this.state.play
        ? this.togglePlay(this.state.soundName)
        : this.togglePause(this.state.soundName);
    });
    this.state.play ? this.stopTimer() : this.startTime();
  };

  handleVolume = () => {
    this.setState({ volume: !this.state.volume }, () => {
      this.state.volume
        ? this.toggleMute(this.state.soundName, false)
        : this.toggleMute(this.state.soundName, true);
    });
  };

  togglePlay = (type) => {
    if (type === "meditation") {
      MeditationAudio.play();
      MeditationAudio.loop = true;
    }
    if (type === "raining") {
      RainingAudio.play();
      RainingAudio.loop = true;
    }
    if (type === "forest") {
      ForestAudio.play();
      ForestAudio.loop = true;
    }
    if (type === "relaxing") {
      RelaxingAudio.play();
      RelaxingAudio.loop = true;
    }
    if (type === "ocean") {
      OceanAudio.play();
      OceanAudio.loop = true;
    }
    if (type === "fire") {
      FireAudio.play();
      FireAudio.loop = true;
    }
    if (type === "birds") {
      BirdsAudio.play();
      BirdsAudio.loop = true;
    }
  };

  toggleCurrentTime = (type) => {
    if (type === "meditation") {
      MeditationAudio.currentTime = 0;
    }
    if (type === "raining") {
      RainingAudio.currentTime = 0;
    }
    if (type === "forest") {
      ForestAudio.currentTime = 0;
    }
    if (type === "relaxing") {
      RelaxingAudio.currentTime = 0;
    }
    if (type === "ocean") {
      OceanAudio.currentTime = 0;
    }
    if (type === "fire") {
      FireAudio.currentTime = 0;
    }
    if (type === "birds") {
      BirdsAudio.currentTime = 0;
    }
  };

  togglePause = (type) => {
    if (type === "meditation") {
      MeditationAudio.pause();
    }
    if (type === "raining") {
      RainingAudio.pause();
    }
    if (type === "forest") {
      ForestAudio.pause();
    }
    if (type === "relaxing") {
      RelaxingAudio.pause();
    }
    if (type === "ocean") {
      OceanAudio.pause();
    }
    if (type === "fire") {
      FireAudio.pause();
    }
    if (type === "birds") {
      BirdsAudio.pause();
    }
  };

  toggleMute = (type, value) => {
    if (type === "meditation") {
      MeditationAudio.muted = value;
    }
    if (type === "raining") {
      RainingAudio.muted = value;
    }
    if (type === "forest") {
      ForestAudio.muted = value;
    }
    if (type === "relaxing") {
      RelaxingAudio.muted = value;
    }
    if (type === "ocean") {
      OceanAudio.muted = value;
    }
    if (type === "fire") {
      FireAudio.muted = value;
    }
    if (type === "birds") {
      BirdsAudio.muted = value;
    }
  };

  render() {
    const { minutes, seconds, play, volume, time, totalSeconds, totalMinutes } =
      this.state;

    return (
      <div>
        <section className="start-hero-main is-medium is-bold hero-custom-bg">
          <div className="hero-body-custom">
            <div className="has-text-centered logo-padding">
              <img src={Logo} width="60" alt="logo" />
            </div>
            <div className="container has-text-centered">
              {this.props.location.state.sessionNumber ? (
                <h2 className="session-subtitle is-uppercase">
                  Session {this.props.location.state.sessionNumber}
                </h2>
              ) : null}

              <h1 className="title custom-title">
                {this.props.location.state.title}
              </h1>

              <svg
                className="moving-outline"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
              >
                <defs>
                  <linearGradient id="header-shape-gradient" x2="0.35" y2="1">
                    <stop offset="0%" stopColor="var(--color-stop)" />
                    <stop offset="30%" stopColor="var(--color-stop)" />
                    <stop offset="100%" stopColor="var(--color-bot)" />
                  </linearGradient>
                </defs>
                <path
                  className="grey"
                  d="M40,90
                     A40,40 0 1,1 60,90"
                  style={{ fill: "none" }}
                />
                <text className="time-display" x="25" y="54">
                  {minutes < 10 ? `0${minutes}` : minutes}:
                  {seconds < 10 ? `0${seconds}` : seconds}
                </text>
                <text x="29" y="70" className="remaining-text">
                  Remaining
                </text>
                <path
                  pathLength={totalSeconds}
                  className="purple"
                  d="M40,90
                     A40,40 0 1,1 60,90"
                  style={{
                    fill: "none",
                    strokeDashoffset: `calc(${
                      totalSeconds -
                      (parseInt(totalMinutes) === 30
                        ? 4
                        : parseInt(totalMinutes) === 20
                        ? 3
                        : parseInt(totalMinutes) === 45
                        ? 5
                        : parseInt(totalMinutes) === 60
                        ? 7
                        : 1)
                    } - ${time})`,
                    strokeDasharray: totalSeconds,
                  }}
                />
              </svg>

              <br />
              <br />

              <Tippy content="End session early">
                <div
                  className="button is-white is-outlined is-rounded"
                  onClick={() => this.handleClose()}
                >
                  <CloseOutlined />
                </div>
              </Tippy>
              <Tippy content="Restart session">
                <div
                  className="button is-white is-outlined is-rounded"
                  onClick={this.handleRefresh}
                >
                  <RedoOutlined />
                </div>
              </Tippy>
              <Tippy content={play ? "Pause session" : "Unpause session"}>
                <div
                  className="button is-white is-outlined is-rounded"
                  onClick={this.handlePlay}
                >
                  {play ? <PauseCircleOutlined /> : <PlayCircleOutlined />}
                </div>
              </Tippy>
              {this.state.soundName === "mute" ? null : (
                <Tippy content={volume ? "Mute music" : "Unmute music"}>
                  <div
                    className="button is-white is-outlined is-rounded"
                    onClick={this.handleVolume}
                  >
                    {volume ? <SoundOutlined /> : <SoundFilled />}
                  </div>
                </Tippy>
              )}
              <Tippy content="Completed session early">
                <div
                  className="button is-white is-outlined is-rounded"
                  onClick={() => this.handleCompleted()}
                >
                  <CheckCircleOutlined />
                </div>
              </Tippy>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Session;
